export default[
    {
      name: "VIRTUAL FACTORY 01",
      title: "The Original Factory",
      anchorId: "the-original-factory",
      img: require("@/assets/original factory/img_origfac_desp.png"),
      text:
        "The Original Factory brings Deloitte Digital's turnkey IoT offering to life. Using your phone or tablet, experience how pairing existing industrial assets and systems with pre-configured turnkey IoT accelerators can enable a digital supply network and unlock measurable value across an entire production line in weeks.",
      blockendImg: require("@/assets/original factory/img_origfac_blockend.png"),
      marker: [{
        img: require("@/assets/General3x3/img_general3x3.png"),
        downloadLink: require("@/assets/General3x3/General3x3.zip"),
        size: { x: 3, y: 3 },
        methric: "ft",
      },{
        img: require("@/assets/original factory/img_origfac42x9.png"),
        downloadLink: require("@/assets/original factory/origfac42x9.zip"),
        size: { x: 42, y: 9 },
        methric: "ft",
      },{
        img: require("@/assets/original factory/img_origfac8x11.png"),
        downloadLink: require("@/assets/original factory/origfac8x11.zip"),
        size: { x: 8.5, y: 11 },
        methric: "inch",
      },],
    },
    {
      name: "VIRTUAL FACTORY 02",
      title: "Showtime",
      anchorId: "showtime",
      img: require("@/assets/showtime/img_showtime_desp.png"),
      text:
        "The Showtime brings an interactive Smart Factory to life on your phone or tablet. Step through 4 use cases and learn about Factory Asset Intelligence, Augmented Worker Solutions, Autonomous Quality Inspection and the Command Center.",
      blockendImg: require("@/assets/showtime/img_showtime_blockend.png"),
      marker: [{
        img: require("@/assets/General3x3/img_general3x3.png"),
        downloadLink: require("@/assets/General3x3/General3x3.zip"),
        size: { x: 3, y: 3 },
        methric: "ft",
      },{
        img: require("@/assets/showtime/img_showtime30x30.png"),
        downloadLink: require("@/assets/showtime/showtime30x30.zip"),
        size: { x: 30, y: 30 },
        methric: "ft",
      },{
        img: require("@/assets/showtime/img_showtime8x11.png"),
        downloadLink: require("@/assets/showtime/showtime8x11.zip"),
        size: { x: 8.5, y: 11 },
        methric: "inch",
      },],
    },
    {
      name: "VIRTUAL FACTORY 03",
      title: "The Digital Thread",
      anchorId: "the-digital-thread",
      img: require("@/assets/digital thread/img_digitalthread_desp.png"),
      text:
        "The Digital Thread describes the framework which connects the data flows across a product's design, manufacturing and life phases to produce a holistic view of a product's end-to-end lifecycle. Experience the many marvels of the Digital Thread including the Digital Twin.",
      blockendImg: require("@/assets/digital thread/img_digthread_blockend.png"),
      marker:[{
        img: require("@/assets/General3x3/img_general3x3.png"),
        downloadLink: require("@/assets/General3x3/General3x3.zip"),
        size: { x: 3, y: 3 },
        methric: "ft",
      },{
        img: require("@/assets/digital thread/img_digthread8x11.png"),
        downloadLink: require("@/assets/digital thread/digthread8x11.zip"),
        size: { x: 8.5, y: 11 },
        methric: "inch",
      },]
    },
  ]