<template>
  <h3>
      {{ factory.name }}
      <div class="anchor" :id="factory.anchorId"></div>
    </h3>
    <h2> {{ factory.title }} </h2>
    <div class="downloadBlock">
      <PreviewComponent :img="factory.img" :text="factory.text" />
      <Flickity class="carousel" ref="flickity" :options="flickityOptions">
        <div class="carousel-cell" v-for="marker in factory.marker" :key="marker.img">
          <DownloadComponent :marker="marker" />
        </div>
      </Flickity>
    </div>
    <img class="bestPracticesBoxBlockendImage" :src="factory.blockendImg" alt />
</template>

<script>
import DownloadComponent from "@/views/DownloadComponent.vue";
import PreviewComponent from "@/views/PreviewComponent.vue";
import Flickity from 'vue-flickity';
import { reactive } from 'vue';
export default {
    name: 'FactoryComponent',
        components: {
        DownloadComponent,
        PreviewComponent,
        Flickity
    },
    
    props: {
        factory: {
          name: String,
          title: String,
          anchorId: String,
          img: String,
          text: String,
          blockendImg: String,
          marker:Array
        }
    },
    watch: {
      flickityOptions: function (n , o) {
        console.log(o,n)  
      }
    },
    methods:{
      setFlickityDots: function(param) {
        this.flickityOptions.pageDots = param;
        console.log(param);
      }
    },
    mounted(){
      window.addEventListener("resize", () => {
        this.screenWidth = window.innerWidth;
      })
    },
    data() {
        return{
            flickityOptions: reactive( {
                initialIndex: 0,
                prevNextButtons: false,
                pageDots: true,
                groupCells: '100%',
                contain: true,
                cellAlign: 'left',
                freeScroll: true,
            }),
        }
    }
}
</script>

<style>
.flickity-page-dots{
  position: relative;
  bottom: -35px;
}
.flickity-page-dots{
  visibility: hidden;
}

.carousel-cell {
  margin-right: 1.7vw;
  height: 29.166667vw;
}

.carousel{
  width: 59.9vw;
  height: 29.166667vw;
}
@media screen and (max-width: 600px){
.flickity-page-dots{
  visibility: visible;
}
.carousel-cell {
    height: 60vw;
  }
.carousel{
    width: 100%;
    height: 60vw;
  }
}
</style>