<template>
  <div class="box">
      <img :src="img" alt="">
      <p>{{ text }}</p>
      
  </div>
</template>

<script>
export default {
  name: 'PreviewComponent',
  props: {
      img: String,
      text: String,
  }
}

</script>

<style scoped>
.box {
    border: 0.15625vw solid #393939; 
    border-radius: 1.041667vw;
    padding: 0;
    width: 29.166667vw;
    height: 29.166667vw;
    margin-right: 1.7vw;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}
.box p {
    position: relative;
    top: -28.385417vw;
    text-align: left;
    margin:0 2.083333vw;
    font-size: 1.041667vw;
}

.box img {
    position: relative;
    width: 100%;
}
@media screen and (max-width: 600px){
    .box {
        width: 100%;
        height: 90vw;
        margin-bottom: 2vw;
    }
    .box p{
        font-size: 4vw;
        top: -85vw;
    }
}
</style>
