<template>
    <div class="navbarDesktop"
      style="position:sticky;
      width: 100%;
      top: 0;
      background:white;
      z-index:1000"
    >
      <div id="nav">
        <p @click="activateButton(0)" 
          :class="{active: state.activeTabIndex == 0}">
          Best Practices</p>
        <p @click="activateButton(1)"
          :class="{active: state.activeTabIndex == 1}">
          The Original Factory</p>
        <p @click="activateButton(2)" 
          :class="{active: state.activeTabIndex == 2}">
          Showtime</p>
        <p @click="activateButton(3)"
          :class="{active: state.activeTabIndex == 3}">
          The Digital Thread</p>
        <h2>PRINTABLE MARKERS</h2>
      </div>
      <div class="gradient"></div>
    </div>
  <div class="navbarMobile">
    <div class="dropdown">
      <button class="mainmenubtn">Menu</button>
        <div class="dropdown-child">
          <a @click="activateButton(0)">Best Practices</a>
          <a @click="activateButton(1)">01 The Original Factory</a>
          <a @click="activateButton(2)">02 Showtime</a>
          <a @click="activateButton(3)" >03 The Digital Thread</a>
        </div>
    </div>
  </div>
  <div class="factoryContent">
    <h3>
      VIRTUAL FACTORY
      <div class="anchor" id="best-practices"></div>
    </h3>
    <h2>Best Practices</h2>
    <div style="text-align: left;">
      <div class="bestPracticesBox">
        <div>
          <img class="bestPracticesBoxImage" :src="require('@/assets/img_bestpractices.png')" alt />
        </div>
        <div style="background: #f0f0f0;border: 0.15625vw solid #f0f0f0;border-radius: 1.041667vw;">
          <p class="bestPracticesBoxText">
            Use non-glossy material as marker base. Print version of the marker should correspond specified parameters. Locate target on float horizontal surface. Don’t cover the target (don’t place any objects on top of it). Make sure the trigger is well-lit (no reflections, no shadows on it). To launch the experience locate your device’s camera directly above the trigger.
          </p>
        </div>
      </div>
    </div>
    <div v-for="factory in factories" :key="factory.img">
      <FactoryComponent :factory="factory"></FactoryComponent>
    </div>
    <div class="footer">
      <span>CREATED BY&ensp;</span>
      <a href="https://BULLYENTERTAINMENT.COM">BULLYENTERTAINMENT.COM</a>
      <span>&ensp;© 2020&ensp;</span>
      <img :src="require('@/assets/footer_logo_bully.png')" alt />
      <span>&ensp;QUESTIONS, COMMENTS, INQUIREIS? WE'RE HERE TO HELP,&ensp;</span>
      <a href="https://CARLSON@BULLYENTERTAINMENT.COM">CARLSON@BULLYENTERTAINMENT.COM</a>
    </div>
  </div>
</template>
<script>
import FactoryComponent from "@/views/FactoryComponent.vue";
import factoriesData from "@/data/factories.js"
console.log(factoriesData)
export default {
  name: "app",
  components: {
    FactoryComponent
  },

  data() {
    return {
      scrolled: false,
      factories: factoriesData,
      state: {
        activeTabIndex: 0,
      },
      options: {
          currentPage: 0
      },
      anchors: [
        "best-practices",
        "the-original-factory",
        "showtime",
        "the-digital-thread"
      ]
    };
  },

  methods: {
    activateButton(i) {
      document
        .getElementById(this.anchors[i])
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },

    next() {
      this.$refs.flickity.next();
    },
    
    previous() {
      this.$refs.flickity.previous();
    },
  },
  mounted() {
    var targets = [];
    targets[0] = document.getElementById("best-practices");
    targets[1] = document.getElementById("the-original-factory");
    targets[2] = document.getElementById("showtime");
    targets[3] = document.getElementById("the-digital-thread");
    var targetPositions = [];
    for (let index = 0; index < targets.length; index++) {
      targetPositions[index] = window.pageYOffset + targets[index].getBoundingClientRect().top;
    }
    window.addEventListener("scroll", () => {
      var windowPosition = window.pageYOffset;
      for (let index = targets.length - 1; index >= 0; index--) {
        var pos = document.documentElement.clientWidth*0.049;        
        if (targetPositions[index] -  pos < windowPosition) {
          history.pushState("", "New Page Title", "#" + this.anchors[index]);
          this.state.activeTabIndex = index;
          break;
        }
      }
    });
  },
};
</script>

<style>

.footer{
  position: sticky;
  display: flex;
  bottom: 0;  
  width: 100%;
  background: #f0f0f0;
  border-radius: 20px 20px 0 0;
  font-size: 0.8vw;
  padding: 1vh 0;
  justify-content: center;
  align-items: center;
}

.footer a{
  color: rgb(83, 167, 0);
  
}
.mainmenubtn {
    background-image: #f0f0f0;
    width: 100vw;
    height: 10vw;
    color: #393939;
    border: none;
    cursor: pointer;
    text-align: left;
    padding-left: 21px;
    position: fixed;
    top:0;
    left:0;
    font-size: 4.083333vw;
    font-weight: bold;
}
.dropdown {
    position: fixed;
    top: 10vw;
    display: inline-block;
    z-index: 1000;
}
.dropdown-child {
    display: none;
    background-color: #f0f0f0;
    min-width: 200px;
    
}
.dropdown-child a {
    color: #393939;
    font-weight: bold;
    padding: 20px;
    text-decoration: none;
    display: block;
    user-select: none;
    cursor: pointer;
}
.dropdown:hover .dropdown-child {
    display: block;
}
.dropdown-child:hover {
  display: block;
}
#app {
  font-family: Open Sans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  color: #393939;
  padding: 0vw 4.167vw 0 4.167vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.factoryContent h2 {
  font-size: 4.166667vw;
  margin: 0 0 1.302083vw 0;
  text-align: left;
}
.factoryContent h3 {
  font-size: 2.083333vw;
  margin: 2.34375vw 0 0 0;
  text-align: left;
  position: relative;
}
.factoryContent{
  width: 100%;
}
#nav {
  font-weight: bold;
  display: inline-flex;
  font-size: 1.302vw;
  width: 100%;
  height: 3.385vw;
  padding-top: 0.15625vw;
  border-bottom: solid 0.260vw #393939;
  position: sticky;
}

#nav p {
  margin-right: 3.906vw;
  cursor: pointer;
}

.active {
  border-bottom: solid 0.625vw #393939;
  padding-bottom: 2.2vw;
}

#nav h2 {
  font-weight: Bold;
  font-size: 2.760vw;
  color: #f0f0f0;
  margin-top: 0.0vw;
}

body {
  margin: 0;
}

.gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 0.521vw,
    transparent 1.042vw,
    #f0f0f0 1.042vw,
    #f0f0f0 1.563vw
  );
  width: 100%;
  height: 1.563vw;
}



.bestPracticesBox > div {
  margin: 0 1.583333vw 3.125vw 0;
  border: 0.15625vw solid #f0f0f0;
  border-radius: 1.041667vw;
  color: #393939;
  font-size: 1.041667vw;
  width: 44.53125vw;
  height: 28.90625vw;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}

.bestPracticesBox > div:last-child {
  margin-right: 0;
}

.bestPracticesBox{
  display:inline-flex;
}
.bestPracticesBoxImage {
  padding: 6.510417vw 4.0625vw 0 1.822917vw;
  text-align: center;
  width: 92%;
}

.bestPracticesBoxText {
  width: 42.447917vw;
  height: 28.90625vw;
  padding: 0.677083vw 1.5vw;
  text-align: left;
  font-size: 1.5vw;
}

.bestPracticesBoxBlockendImage {
  display: block;
  margin-top: 2vw;
  margin-bottom: 9.114583vw;
  width: 100%;
}
.bestPracticesBox > div:last-child{
    margin-right: 0;
}
.anchor {
  position: absolute;
  top: -5.208333vw;
  left: 0;
}
.downloadBlock {
  display:inline-flex;
}
.navbarMobile{
  display: none;
}
.navbarDesktop{
  display: block;
}

@media screen and (max-width: 600px){
  .navbarDesktop{
    display: none;
  }
  .navbarMobile{
    display: block;
  }
 .bestPracticesBox{
   display: block;
 }
  .bestPracticesBox > div{
    width: 100%;
    height: auto;
  }
  .bestPracticesBoxText{
    width: 90%;
    font-size: 3vw;
    height: auto;
    margin: auto;
  }
  .downloadBlock{
    display: block;
  }
  
  #nav{
    height: 11vw;
    width: 100%;
  }
  .factoryContent{
    margin-top: 10vw;
  }
  .factoryContent h2 {
  font-size: 8.166667vw;
  margin: 0 0 1.302083vw 0;
  text-align: left;
  }
  .factoryContent h3 {
    font-size: 4.083333vw;
    margin: 2.34375vw 0 0 0;
    text-align: left;
    position: relative;
  }
  #nav p{
    font-size: 3vw;
    margin-right: 0vw;
  }
  #nav h2 {
    font-weight: Bold;
    font-size: 3vw;
    color: #f0f0f0;
    margin-top: 3vw;
  }
  .active {
    border-bottom: solid 1.250vw #393939;
    padding-bottom: 8vw;
  }
  .gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent 1.042vw,
    transparent 2.084vw,
    #f0f0f0 2.084vw,
    #f0f0f0 3.126vw
  );
  width: 100%;
  height: 3.126vw;
  }
  .anchor {
  position: absolute;
  top: -10vw;
  left: 0;
}
.footer{
  font-size: 3vw;
  display: block;
  text-align: center;
}
.footer img{
  width: 10%;
}
}
</style>
