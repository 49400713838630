<template>
  <div class="box">
      <div class="imageBox">
        <img :src="marker.img" alt="">
      </div>
      
      <div style="margin:4% 0 2% 0">
        <span class="span1" style="">Print Size: </span>
        <span class="span2" style=""> {{ marker.size.x }} x {{ marker.size.y }} {{ marker.methric }}</span>
      </div>
      <button><a style="color: #393939;text-decoration: none" :href="marker.downloadLink" download>DOWNLOAD</a></button>
  </div>
</template>

<script>
export default {
  name: 'DownloadComponent',
  props: {
    marker: {
      img: String,
      size: Object,
      methric: String,
      downloadLink: String,
    }
  },
}

</script>

<style scoped>
.box {
  border: 0.15625vw solid #393939; 
  border-radius: 1.041667vw;
  width: 18.75vw;
  height: 29.166667vw;
  
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
}
.imageBox{
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.15625vw solid #393939; 
  border-radius: 1.041667vw;
  position: relative;
  width: 16.666667vw;
  height: 16.666667vw;
  top:0.885417vw;
  overflow: hidden;
  margin: auto;
  align-items:center;
  display:flex;
}
.imageBox img{
  width: 100%;
}
.span1{
    font-size: 1.302083vw;
  }
.span2{
  font-size: 1.822917vw;
}
.box button{
  background: repeat url(../assets/T_ui_01_Pattern_popup_UseCases_Button.png);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.15625vw solid #393939;
  border-radius: 1.041667vw;
  font-size: 1.5625vw;
  padding: 1.5625vw 3.385417vw;
  font-weight: bold;
  
  background-size: contain;
}
@media screen and (max-width: 600px){
  .box{
    height: 60vw;
    width: 43vw;
  }
  .imageBox{
    margin-top: 3vw;
    width: 80%;
    height: 60%;
  }
  .box button{
    font-size: 3vw;
  }
  .span1{
    font-size: 2.6vw;
  }
  .span2{
    font-size: 3.6vw;
  }
}
</style>
